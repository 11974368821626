import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'

import { myContext } from '../../provider'

const StyledFooter = styled.footer`
  background-color: #4a4a4a;
  padding: 3rem;
  font-family: 'Montserrat';
`

const FooterSection = styled(Col)`
  color: white;
  font-size: 0.875rem;
  p {
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 1.5rem;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter {
        nodes {
          hours {
            hours
          }
          language
        }
      }
    }
  `)
  const nodes = data.allContentfulFooter.nodes
  return (
    <myContext.Consumer>
      {({ language, changeLanguage }) => {
        const footerText = nodes.find((node) => node.language === language)

        return (
          <StyledFooter>
            <Container>
              <Row className="justify-content-md-center">
                <FooterSection xs={12} md={3}>
                  <p>
                    VINA INSURANCE SERVICES LTD. <br /> <br />
                    1192 Kingsway <br />
                    Vancouver, BC. V5V 3C8
                    <br /> <br />
                    Tel. 604 437 6669 <br />
                    Fax. 604 437 6668 <br /> <br />
                    Email. info@vina.ca <br />
                    Web. www.vina.ca
                  </p>
                </FooterSection>
                <FooterSection xs={12} md={3}>
                  <p
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {footerText.hours.hours}
                  </p>
                </FooterSection>
                <FooterSection xs={12} md={3}>
                  <p>
                    VINA INSURANCE SERVICES LTD. <br />
                    1192 Kingsway <br />
                    Vancouver, BC. V5V 3C8
                    <br />
                    Tel. 604 437 6669 <br />
                    Fax. 604 437 6668 <br />
                    Email. info@vina.ca <br />
                    Web. www.vina.ca
                  </p>
                </FooterSection>
              </Row>
            </Container>
          </StyledFooter>
        )
      }}
    </myContext.Consumer>
  )
}

export default Footer
