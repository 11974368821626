import { Link, graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Collapse } from 'react-bootstrap'

import { myContext } from '../../provider'

const StyledHeader = styled.header`
  padding: 0 0 2rem 0;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #979797;

  @media (max-width: 980px) {
    padding: 1.25rem 0;
  }
`

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-family: 'Montserrat';
    font-weight: 700;

    a {
      font-size: 1.25rem;
      color: #ea6c07;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 980px) {
    padding: 0 1rem;
    h1 {
      a {
        font-size: 1.25rem;
      }
    }
  }
`
const UL = styled.ul`
  list-style: none;

  @media (max-width: 980px) {
    display: none;
  }
`

const ListItem = styled.li`
  display: inline-flex;
  margin-left: 2.1rem;
  font-family: 'Montserrat';
  text-transform: uppercase;

  a {
    font-weight: 500;
    text-decoration: none;
    color: black;
    font-size: 1rem;
  }
`

const MobileMenuButton = styled.div`
  display: none;

  @media (max-width: 980px) {
    display: block;
  }
`

const MobileMenu = styled.div`
  ${UL} {
    list-style: list;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const CovidHeader = styled.div`
  height: 40px;
  background-color: #ea6c07;
  margin-bottom: 2rem;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;

  a {
    color: white;
    text-decoration: underline;
    margin-left: 10px;
  }
`

const Language = styled.span`
  &:hover {
    cursor: pointer;
  }
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      allContentfulMenuItems {
        nodes {
          aboutLabel
          contactUsLabel
          language
          quoteLabel
          servicesLabel
          blogLabel
          renewalLabel
        }
      }
    }
  `)

  const nodes = data.allContentfulMenuItems.nodes
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const openMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }
  return (
    <myContext.Consumer>
      {({ language, changeLanguage }) => {
        const navText = nodes.find((node) => node.language === language)
        return (
          <StyledHeader>
            <CovidHeader>
              Covid Updates:{'  '}
              <Link to="/covid/"> Vina keeps Clients & Staff Safe</Link>
            </CovidHeader>
            <HeaderContainer>
              <h1>
                <Link to="/">{siteTitle}</Link>
              </h1>
              <UL>
                <ListItem>
                  <Link to="/about/">{navText.aboutLabel}</Link>
                </ListItem>
                <ListItem>
                  <Link to="/blog/">{navText.blogLabel}</Link>
                </ListItem>
                <ListItem>
                  <Link to="/quote/">{navText.quoteLabel}</Link>
                </ListItem>
                <ListItem>
                  <Link to="/renewal/">{navText.renewalLabel}</Link>
                </ListItem>
                <ListItem>
                  <Link to="/contact/">{navText.contactUsLabel}</Link>
                </ListItem>
                <ListItem>
                  <Language
                    style={{
                      textDecoration: language === 'english' ? 'underline' : '',
                      paddingRight: 5,
                    }}
                    onClick={() => changeLanguage('english')}
                  >
                    English{' '}
                  </Language>
                  |
                  <Language
                    style={{
                      textDecoration:
                        language === 'vietnamese' ? 'underline' : '',
                      paddingRight: 5,
                    }}
                    onClick={() => changeLanguage('vietnamese')}
                  >
                    {' '}
                    Tiếng Việt
                  </Language>
                </ListItem>
              </UL>
              <MobileMenuButton onClick={openMenu}>
                {showMobileMenu ? <FaTimes /> : <FaBars />}
              </MobileMenuButton>
            </HeaderContainer>
            <Collapse in={showMobileMenu}>
              <MobileMenu>
                <UL>
                  <ListItem>
                    <Link to="/about/">{navText.aboutLabel}</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/services/">{navText.servicesLabel}</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/quote/">{navText.quoteLabel}</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/renewal/">{navText.renewalLabel}</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/contact/">{navText.contactUsLabel}</Link>
                  </ListItem>
                  <ListItem>
                    <Language
                      style={{
                        textDecoration:
                          language === 'english' ? 'underline' : '',
                        paddingRight: 5,
                      }}
                      onClick={() => changeLanguage('english')}
                    >
                      English{' '}
                    </Language>
                    |
                    <Language
                      style={{
                        textDecoration:
                          language === 'vietnamese' ? 'underline' : '',
                        paddingRight: 5,
                      }}
                      onClick={() => changeLanguage('vietnamese')}
                    >
                      {' '}
                      Tiếng Việt
                    </Language>
                  </ListItem>
                </UL>
              </MobileMenu>
            </Collapse>
          </StyledHeader>
        )
      }}
    </myContext.Consumer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
